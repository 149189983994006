import React from "react";
import Moment from "moment";
import { useTranslation } from "react-i18next";
function OrderHistory({
  exchange,
  marketTradeData,
  marketTrade,
  binanceTrades,
  MarketTrade,
  params,
}) {

  const { t } = useTranslation();
  const marketTradeHtml = () => {
    
    if (exchange === "binance") {
      if (binanceTrades) {
        return (
          <tbody>
            <MarketTrade
              trades={binanceTrades}
              record={[]}
              currentPair={params.firstCoin + "/" + params.secondCoin}
            />
          </tbody>
        );
      }
    } else {
      if (marketTrade) {
        var collectHtml = [];
        for (var i = 0; i < marketTradeData.length; i++) {
          var singleData = marketTradeData[i];
         // console.log("singleData ",singleData);
          var setColor = singleData.extype === "buy" ? "green" : "magenta";
         // YYYY-MM-DD HH:mm:ss
          var getTime = Moment(singleData.created_at).format("MMM DD, YYYY, hh:mm:ss");
          collectHtml.push(
            <tr className="ng-scope" key={singleData.id}>
              <td className={"f-left ng-binding " + setColor}>
                {parseFloat(singleData.get_per_price).toFixed(6)}
              </td>
              <td className="f-left ng-binding">{singleData.get_amount}</td>
              <td className="f-left ng-binding">
                {singleData.firstCoin + "/" + singleData.secondCoin}
              </td>
              <td className="f-left ng-binding">{getTime}</td>
            </tr>
          );
        }
        return <tbody>{collectHtml}</tbody>;
      } else {
        return (
          <tbody>
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                No data avaliable
              </td>
            </tr>
          </tbody>
        );
      }
    }
  };
  return (
    <div className="col-md-4  ">
      <div className="ne_box h_100 br_0">
        <div className="market-trade">
          <div className="tradehistory tradehistory-new ">
            <div className="item-con tradeHistory-base" id="tradeHistory">
              <h6 className="h6_head">{t("Order History")}</h6>
              <div className="scrollStyle ng-scope">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>{t("Price")}</th>
                      <th>{t("Amount")}</th>
                      <th>{t("Pair")}</th>
                      <th>{t("Date & Time")}</th>
                    </tr>
                  </thead>
                  {marketTradeHtml()}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
